/* HelpCentre.css */
.help-centre {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .help-centre h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .help-section {
    margin-bottom: 20px;
  }
  
  .help-section p {
    font-size: 16px;
  }
  
  .help-section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .help-section ul li {
    font-size: 16px;
    margin: 10px 0;
  }
  