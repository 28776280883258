/* Main container */
.cart-container {
  margin-top: 30px;
  padding-bottom: 40px;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Cart Heading */
.cart-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

/* Empty Cart Message */
.empty-cart-message {
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  font-style: italic;
  margin-top: 20px;
}

/* Cart item column styling */
.cart-item {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cart-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Product title */
.cart-item h5 {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Price and Quantity */
.cart-item p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
}

/* Button styles */
.btn {
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

/* Remove button */
.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
}

.btn-danger:hover {
  background-color: #c82333;
}

/* Increase quantity button */
.btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-success:hover {
  background-color: #218838;
}

/* Proceed to checkout button - GREEN */
.checkout-btn {
  background-color: #28a745;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 12px 30px;
  border-radius: 8px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #218838;
}

/* Total price styling */
.total-price {
  text-align: right;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
  color: #333;
}
@media (max-width: 992px) {
  .cart-container {
    padding: 15px;
  }

  .cart-heading {
    font-size: 2rem;
  }

  .cart-item h5 {
    font-size: 1.5rem;
  }

  .cart-item p {
    font-size: 1rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .checkout-btn {
    font-size: 1rem;
    padding: 10px 25px;
  }

  .total-price {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .cart-container {
    padding: 10px;
  }

  .cart-heading {
    font-size: 1.8rem;
  }

  .cart-item h5 {
    font-size: 1.3rem;
  }

  .cart-item p {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .checkout-btn {
    font-size: 0.9rem;
    padding: 8px 20px;
  }

  .total-price {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .cart-heading {
    font-size: 1.5rem;
  }

  .cart-item {
    padding: 15px;
  }

  .cart-item h5 {
    font-size: 1.2rem;
  }

  .cart-item p {
    font-size: 0.8rem;
  }

  .btn {
    font-size: 0.7rem;
    padding: 5px 10px;
  }

  .checkout-btn {
    font-size: 0.8rem;
    padding: 6px 15px;
  }

  .total-price {
    font-size: 1.1rem;
  }
}