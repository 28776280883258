/* Orders.css */
.orders {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .orders h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .order-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .order-item:last-child {
    border-bottom: none;
  }
  
  .order-item p {
    margin: 5px 0;
    font-size: 16px;
  }
  