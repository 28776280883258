/* Blog Page */
.blog-page1 {
  margin-top: 20px;
}

/* Breadcrumb */
.breadcrumb {
  font-size: 16px;
  color: #b7410e;
}

/* Blog Card */
.blog-card1 {
  margin-bottom: 20px;
  border: none;
}

/* Blog Image */
.blog-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1; /* Set opacity to 1 for all images */
}
/* Adjust blog page layout */
.blog-page1 .col-lg-9 {
  padding-right: 15px;
}

.blog-page1 .col-lg-3 {
  padding-left: 15px;
}

/* Blog Card */
.blog-card1 {
  margin-bottom: 20px;
  border: none;
  width: 100%; /* Ensure full width inside the column */
}

/* Blog Image */
.blog-image {
  height: auto;
  width: 100%;
  object-fit: cover;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .blog-page1 .col-lg-9 {
    padding-right: 10px;
  }

  .blog-page1 .col-lg-3 {
    padding-left: 10px;
  }
}
/* If you want to ensure this applies to all images in the blog page */
.blog-page img {
  opacity: 1; /* Set opacity to 1 for all images */
}
/* Publish Date */
.publish-date {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 10px;
}

.publish-date span {
  font-weight: bold;
}

/* Read More Button */
.read-more-btn {
  background-color: #b7410e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.read-more-btn:hover {
  background-color: #96350b;
  color: white;
}

/* Appointment Card */
.appointment-card {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Category Card */
.category-card {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Category List */
.category-list {
  list-style: none;
  padding-left: 0;
}

.category-list li {
  font-size: 16px;
  color: #b7410e;
  margin-bottom: 10px;
  cursor: pointer;
}

.category-list li:hover {
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .blog-page {
    margin-top: 15px;
  }

  .breadcrumb {
    font-size: 14px;
  }

  .blog-card {
    margin-bottom: 15px;
  }

  .publish-date {
    font-size: 13px;
  }

  .read-more-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .appointment-card,
  .category-card {
    padding: 15px;
  }

  .category-list li {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .blog-page {
    margin-top: 10px;
  }

  .breadcrumb {
    font-size: 12px;
  }

  .blog-card {
    margin-bottom: 10px;
  }

  .publish-date {
    font-size: 12px;
  }

  .read-more-btn {
    padding: 6px 12px;
    font-size: 12px;
  }

  .appointment-card,
  .category-card {
    padding: 10px;
  }

  .category-list li {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .blog-page {
    margin-top: 5px;
  }

  .breadcrumb {
    font-size: 10px;
  }

  .blog-card {
    margin-bottom: 5px;
  }

  .publish-date {
    font-size: 10px;
  }

  .read-more-btn {
    padding: 4px 10px;
    font-size: 10px;
  }

  .appointment-card,
  .category-card {
    padding: 8px;
  }

  .category-list li {
    font-size: 10px;
  }
}