.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the content horizontally */
  justify-content: center; /* Center align the content vertically */
  height: 100vh; /* Full viewport height */
  position: relative; /* Position relative for absolute positioning of the overlay */
  background-image: url('https://i0.wp.com/gdcindia.co.in/wp-content/uploads/2019/03/contact-us-background.jpg?fit=1600%2C650&ssl=1'); /* Background image */
  background-size: cover;
  background-position: center;
  color: rgb(0, 0, 0);
  padding: 40px;
  overflow: hidden; /* Ensures overlay stays within contact-page bounds */
  opacity: 0; /* Start with page hidden */
  animation: fadeIn 0.8s forwards; /* Fade in animation */
}

.contact-page::before {
  content: "";
  position: absolute; /* Position overlay absolutely */
  top: 0;
  left: 0;
  right: 0; /* Cover entire width */
  bottom: 0; /* Cover entire height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Ensure overlay is below the content but above the background */
}

.contact-page * {
  position: relative; /* Position text above the overlay */
  z-index: 2; /* Ensure all content is above the overlay */
  opacity: 0; /* Start all content as hidden */
  animation: slideIn 0.5s forwards; /* Slide in animation for all content */
  animation-delay: 0.2s; /* Delay for the content to appear */
}

.contact-container {
  display: flex;
  flex-direction: column; /* Stack form and content vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  width: 100%; 
  max-width: 1200px; 
  margin-top: 20px;
  height: 100%; /* Ensures container takes full height */
  color: #fefefe;
}

.contact-form {
  display: flex;
  flex-direction: column; 
  justify-content: center; /* Center form elements */
  align-items: center; /* Center form horizontally */
  width: 100%; 
  max-width: 500px; 
  background: rgba(255, 255, 255, 0.1); /* Slightly transparent background for the form */
  padding: 30px; /* Add some padding around the form */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow to the form */
  text-align: left;
  color: #218838;
  opacity: 0; /* Start with form hidden */
  animation: scaleIn 0.5s forwards; /* Scale in animation */
  animation-delay: 0.3s; /* Delay for the form to appear */
}

.contact-form label {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem; 
  margin-bottom: 5px; 
  color: rgb(255, 255, 255); /* Set labels to white for visibility */
  font-weight: bold;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px; 
  padding: 10px; 
  border: none; 
  border-radius: 4px; 
  font-size: 1rem; 
  background: rgba(255, 255, 255, 0.9); 
  width: 100%; /* Ensure the form elements take full width */
}

.contact-form button {
  padding: 10px 20px; 
  background-color: #28a745; 
  color: white; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  font-size: 1rem; 
  transition: background-color 0.3s;
  width: 100%; /* Button spans the form's width */
}

.contact-form button:hover {
  background-color: #218838; 
}

.contact-page p {
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
  color: white;
}

.contact-image {
  width: 50%;
  text-align: right; /* Align image container to the right */
  margin-bottom: 200px;
}

.contact-image img {
  max-width: 100%; /* Responsive image */
  height: auto;
  border-radius: 10px;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start invisible */
  }
  to {
    opacity: 1; /* End fully visible */
  }
}

/* Keyframes for slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(20px); /* Start below */
    opacity: 0; /* Invisible */
  }
  to {
    transform: translateY(0); /* End at normal position */
    opacity: 1; /* Fully visible */
  }
}

/* Keyframes for scaling animation */
@keyframes scaleIn {
  from {
    transform: scale(0.9); /* Start slightly smaller */
    opacity: 0; /* Invisible */
  }
  to {
    transform: scale(1); /* End at normal size */
    opacity: 1; /* Fully visible */
  }
}

/* Responsive styles */

/* For Tablets */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px; 
  }

  .contact-container {
    flex-direction: column; /* Stack form and image vertically */
    align-items: center; /* Center items */
  }

  .contact-image {
    width: 100%; /* Full width on tablets */
    display: block; /* Show image on tablets */
    text-align: center; /* Center the image */
  }

  .contact-form {
    width: 90%; /* Slightly narrower on tablets */
  }
}

/* For Mobile Devices */
@media (max-width: 480px) {
  .contact-page {
    padding: 15px;
  }

  .contact-page h2 {
    font-size: 1.8rem; /* Smaller heading */
  }

  .contact-page p {
    font-size: 1rem; /* Smaller paragraph */
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-form button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .contact-image {
    display: none; /* Hide image on mobile */
  }
}