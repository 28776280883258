/* Main Container for the Disease Detail */
.disease-detail {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    background-color: #e7f9e7;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.disease-detail:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

/* Flex Container for Side-by-Side Layout */
.flex-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 20px;
    margin-bottom: 30px;
}

.disease-detail-left {
    flex: 1 1 50%; /* Flex grow, shrink, basis */
    padding: 20px;
}
.disease-detail-right {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items horizontally */
    justify-content: flex-start; /* Align items to the top */
    padding: 20px; /* Add some padding around */
}

/* Image Styling in Right Container */
.disease-detail-right img {
    width: 90%; /* Make it responsive */
    max-width: 400px; /* Set a max width */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    border: 3px solid rgba(88, 216, 107, 0.229); /* Border color */
    margin-bottom: 15px; /* Space below the image */
    animation: fadeIn 0.5s ease-in; /* Fade-in effect */
    order: -1; /* Bring the image to the top if there are multiple flex items */
    margin-top: 20px;
    margin-bottom: 50px;
}
/* Right Container Text Styling */
.disease-detail-right p {
    font-size: 1em;
    line-height: 1.5;
    color: #444;
    margin: 0;
    padding: 0 10px;
}

/* Review Section */
.review-section {
    margin-top: 40px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Review Content */
.review-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.review-content {
    margin: 0 20px;
    text-align: left;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.review-content:hover {
    transform: scale(1.05);
}

/* Review Image */
.review-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    transition: transform 0.3s ease;
}

.review-image:hover {
    transform: scale(1.1);
}

/* Toggle Button */
.toggle-button {
    background-color: #4caf50;
    border: none;
    color: white;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-button:hover {
    background-color: #388e3c;
    transform: scale(1.1);
}

/* Headings */
h1 {
    font-size: 2em;
    color: #2c6f2f;
    margin-top: 10px;
    margin-bottom: 1px;
    text-align: center;
    padding: 20px;
    animation: fadeIn 0.5s ease-in;
}

h2 {
    font-size: 2em;
    color: #2c6f2f;
    margin-bottom: 5px;
    text-align: left;
    animation: fadeIn 0.5s ease-in;
    padding: 20px;
}

/* Paragraphs */
p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
    opacity: 0; /* Initial opacity for fade-in */
    animation: fadeIn 0.5s ease-in forwards;
}

/* Lists */
ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

/* Links */
a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .disease-detail {
        padding: 25px;
    }

    h1 {
        font-size: 2.2em;
    }

    h2 {
        font-size: 1.8em;
    }
}

@media (max-width: 992px) {
    .flex-container {
        flex-direction: column;
    }

    .disease-detail-left,
    .disease-detail-right {
        padding: 0;
        flex: 1 1 100%;
    }

    .toggle-button {
        font-size: 1.5em;
        padding: 12px;
    }

    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 768px) {
    .disease-detail {
        padding: 15px;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.3em;
    }

    p {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .disease-detail-right img {
        width: 40%;
        height: 40%;
    }

    .review-image {
        width: 60px;
        height: 40px;
    }

    .toggle-button {
        font-size: 1.2em;
        padding: 8px;
    }
}