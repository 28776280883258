.sticky-appointment-btn {
    position: fixed;
    right: 42%;
    top: 95%; /* Lowered position */
    transform: translateY(-50%);
    /* background: linear-gradient(135deg, #007bff, #0056b3); Attractive gradient */
    background: linear-gradient(135deg, #007bff, #0056b3); /* Attractive gradient */
    color: #ffffff;
    padding: 15px 25px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow  */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between icon and text */
    transition: all 0.3s ease;
    opacity: 0.9;
  }
  
  .sticky-appointment-btn:hover {
    background: linear-gradient(135deg, #0056b3, #003a80); /* Darker gradient on hover */
    opacity: 1; /* Fully opaque on hover */
    transform: translateY(-50%) scale(1.05); /* Slight zoom on hover */
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
  }
  
  /* Icon styling */
  .sticky-appointment-btn i {
    font-size: 20px; /* Adjust icon size */
  }
  
  @media (max-width: 768px){
    .sticky-appointment-btn{
      right: 35%;
    }
  }