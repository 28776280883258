/* HealthAndWellness.css */

.health-wellness-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
}

/* Banner Section */
.wellness-banner {
  background: url('https://images.unsplash.com/photo-1495461199391-8c39ab674295?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXl1cnZlZGF8ZW58MHx8MHx8fDA%3D') center/cover no-repeat; /* Background image */
  color: white;
  padding: 80px 0; /* Increased padding for better visibility */
  text-align: center; /* Centering text */
  position: relative; /* Positioning context for pseudo-element */
  overflow: hidden; /* Hide overflow for the pseudo-element */
}

.wellness-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  opacity: 0; /* Initial state for animation */
  animation: fadeIn 1.5s forwards; /* Fade in animation */
}

@keyframes fadeIn {
  to {
    opacity: 0.5; /* Final state of the overlay */
  }
}

.banner-title {
  font-size: 3rem; /* Larger font size for banner title */
  margin: 0;
  font-weight: bold;
  animation: slideIn 1s forwards; /* Slide-in animation */
  opacity: 0; /* Initial opacity */
}

@keyframes slideIn {
  from {
    transform: translateY(-50px); /* Start above */
    opacity: 0; /* Initial opacity */
  }
  to {
    transform: translateY(0); /* End position */
    opacity: 1; /* Final opacity */
  }
}

/* Key Benefits Section */
.key-benefits {
  background-color: #f9f9f9; /* Light background for contrast */
  padding: 50px 0; /* Added padding for spacing */
}

.section-title {
  font-size: 2.5rem; /* Increased section title size */
  margin-bottom: 40px; /* More margin for better spacing */
  font-weight: bold;
  color: #4caf50; /* Green color for section titles */
}

/* Benefits Row */
.benefits-row {
  justify-content: center;
}

/* Benefit Cards */
.benefit-card {
  border: none;
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow for depth */
  padding: 30px; /* Increased padding */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  margin-bottom: 30px; /* Margin between cards */
  background-color: white; /* White background for cards */
  height: 300px;
}

.benefit-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.benefit-icon {
  width: 80px; /* Set a consistent size for benefit icons */
  height: 80px; /* Maintain aspect ratio */
  margin-bottom: 15px; /* Space below the icon */
}

.benefit-title {
  font-size: 1.8rem; /* Increased font size for titles */
  margin: 15px 0; /* Spacing around titles */
  font-weight: bold;
}

.benefit-text {
  font-size: 1.1rem; /* Slightly larger font size for descriptions */
  color: #666; /* Gray color for text */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .banner-title {
    font-size: 2.5rem; /* Adjusted for smaller screens */
  }

  .section-title {
    font-size: 2rem; /* Adjusted for smaller screens */
  }

  .benefit-title {
    font-size: 1.5rem; /* Adjusted for smaller screens */
  }

  .benefit-text {
    font-size: 1rem; /* Adjusted for smaller screens */
  }

  .benefit-icon {
    width: 60px; /* Smaller icon for smaller screens */
    height: 60px; /* Maintain aspect ratio */
  }
}