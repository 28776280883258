.product-gallery-container {/* Padding around the gallery */
  background-color: #f8f9fa; /* Light background color */
  text-align: center; /* Center-aligning text */
}

.one {
  font-size: 2rem; /* Font size for the title */
  font-weight: bold; /* Bold title */
  margin-bottom: 10px; /* Margin below the title */
  color: #333; /* Dark color for the title */
}

.product-subtitle {
  font-size: 1rem; /* Smaller font for subtitle */
  color: #666; /* Gray color for subtitle */
  margin-bottom: 20px; /* Margin below the subtitle */
}

/* Slider Settings */
.gallery-item {
  display: flex;
  justify-content: center; /* Center the image */
  align-items: center; /* Center the image vertically */
  padding: 10px; /* Padding for the gallery items */
}

.product-card {
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures child elements don't overflow */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.product-image {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.2s; /* Smooth zoom effect */
}

.product-image:hover {
  transform: scale(1.05); /* Zoom in effect on hover */
}

/* Arrows styles */
.slick-next, .slick-prev {
  z-index: 1; /* Ensure arrows are on top */
  font-size: 24px; /* Size of arrow icons */
  color: #333; /* Color for arrows */
}

.slick-next {
  right: 15px; /* Position next arrow */
}

.slick-prev {
  left: 15px; /* Position previous arrow */
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .product-image {
    height: 200px; /* Set a height for large screens */
  }
}

@media (max-width: 992px) {
  .product-image {
    height: 180px; /* Set a height for medium screens */
  }
}

@media (max-width: 768px) {
  .product-image {
    height: 160px; /* Set a height for small screens */
  }
}

@media (max-width: 576px) {
  .product-image {
    height: 140px; /* Set a height for extra small screens */
  }
}