/* Base styles */
.details-section {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1;
}

.details-section:hover .overlay {
  opacity: 1;
}

.image-section, .text-section {
  flex: 1;
  padding: 20px;
  position: relative;
  z-index: 2;
}

.clinic-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.subtitle {
  color: #ff9900;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

/* Why Choose Us Section */
.why-us-section {
  text-align: center;
  background-color: #fff;
  padding: 50px;
  position: relative;
}

.why-us-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.why-us-cards {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.card:hover {
  transform: translateY(-10px);
}

.card-image {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  object-fit: cover;
}

.card-title {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #333;
}

.card-description {
  font-size: 1rem;
  margin-top: 10px;
  color: #555;
}

/* Mission and Vision Section */
.mission-vision-section {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background-color: #f1f1f1;
  position: relative;
}

.mission-section, .vision-section {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.mission-section img, .vision-section img {
  max-width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  height: auto;
}

.mission-section h2, .vision-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.mission-section p, .vision-section p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

/* Animations */
.animate-mission {
  animation: fadeInLeft 2s ease-in-out;
}

.animate-vision {
  animation: fadeInRight 2s ease-in-out;
}

@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translateX(-100%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      transform: translateX(100%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .details-section, .mission-vision-section {
      flex-direction: column;
      padding: 30px;
  }

  .why-us-title {
      font-size: 2rem;
  }

  .title {
      font-size: 2rem;
  }

  .why-us-cards {
      gap: 20px;
  }
}

@media (max-width: 768px) {
  .why-us-cards {
      flex-direction: column;
      align-items: center;
  }

  .card {
      max-width: 90%;
  }

  .mission-section h2, .vision-section h2 {
      font-size: 2rem;
  }

  .subtitle {
      font-size: 1.3rem;
  }

  .title {
      font-size: 2rem;
  }

  .description, .mission-section p, .vision-section p {
      font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .details-section {
      padding: 20px;
  }

  .title, .why-us-title, .mission-section h2, .vision-section h2 {
      font-size: 1.8rem;
  }

  .subtitle, .card-title {
      font-size: 1.2rem;
  }

  .description, .card-description {
      font-size: 1rem;
  }

  .card-image {
      height: 200px;
  }

  .mission-vision-section {
      padding: 20px;
  }
}