/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}
.scroll-effect {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.scroll-effect.visible {
  opacity: 1;
}

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.sidebar {
  width: 30%;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 15px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.sidebar ul li a:hover {
  color: #007bff;
}

.sidebar ul li a.active {
  color: #007bff;
} */


/* Sidebar Styling */

.sidebar {
  width: 30%;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 15px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.sidebar ul li a:hover {
  color: #007bff;
}

.sidebar ul li a.active {
  color: #007bff;
}

.main-content {
  width: 70%;
  padding: 20px;
}

/* Add any specific styling for the pages if needed */

/* Add more layout and style as per your requirement */
