.profile-details {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-details h2 {
  margin-bottom: 20px;
}

.profile-field {
  margin-bottom: 15px;
}

.profile-field label {
  display: inline-block;
  width: 150px;
  font-weight: bold;
}

.profile-field span {
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.profile-field input {
  width: 300px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn {
  padding: 10px 15px;
  margin-top: 10px;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}
