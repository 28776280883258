/* Top Bar */
.top-bar {
  background-color: #28a745; /* Green background */
  padding: 10px 0;
  font-size: 14px;
}

.discount-text {
  font-weight: bold;
  text-transform: capitalize;
}

.contact-info {
  font-size: 14px;
  margin-left: 500px;
  white-space: nowrap; /* Prevent wrapping */
}

.contact-info span {
  margin-right: 5px;
}

/* Navbar */
.navbar {
  padding: 0;
  background-color: #000000;
  height: 150px;
}

/* Navbar Links */
.navbar-nav .nav-item .nav-link {
  font-size: 14px;
  padding: 8px 15px;
  color: white; /* Text color */
  transition: color 0.3s ease;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #28a745; /* Hover color */
}

.nav-item {
  padding: 5px;
}

.nav-item:hover {
  scale: 1.2;
}

.navbar-collapse {
  margin-left: 100px;
}

/* Navbar Icons */
.nav-icons {
  display: flex;
  align-items: center;
  margin-right: 60px;
}

.nav-icon {
  margin-left: 15px;
  font-size: 18px;
  color: #000;
  padding: 2px;
  transition: color 0.3s ease;
}

.nav-icon:hover {
  color: #28a745;
}

/* Logo */
.navbar .logo {
  width: 300px;
}

/* Navbar Toggler */
.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="%23000000" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"/></svg>');
}

/* AOS specific styles */
[data-aos="fade-down"],
[data-aos="fade-up"],
[data-aos="fade-left"] {
  opacity: 0;
  transform: translateY(20px); /* Adjust translation for smooth animation */
}

[data-aos="fade-down"].aos-animate,
[data-aos="fade-up"].aos-animate,
[data-aos="fade-left"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Styles */

/* Medium Devices (tablets, 768px and up) */
@media (max-width: 1366px) {
  .top-bar {
    display: none;
  }

  .navbar {
    padding: 8px 0;
  }

  .logo {
    max-width: 180px;
    padding-left: 30px;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 9px;
    padding: 8px 12px;
  }

  .nav-icons .nav-icon {
    font-size: 17px;
    margin-left: 10px;
  }
}

/* Small Devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .top-bar {
    padding: 8px 0;
    font-size: 12px;
  }

  .discount-text,
  .contact-info {
    text-align: center;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
  }

  .navbar-collapse {
    margin-left: 0;
    padding: 0 10px;
  }

  .navbar-nav .nav-item {
    margin: 5px 0;
  }

  .nav-icons {
    margin-right: 0;
    justify-content: center;
  }

  .navbar .logo {
    max-width: 180px;
  }
}

/* Extra Small Devices (phones, less than 576px) */
@media (max-width: 480px) {
  .navbar .logo {
    max-width: 120px;
  }
  
  .navbar {
    height: auto;
  }

  .navbar-nav .nav-link {
    font-size: 9px;
    padding: 6px 10px;
  }

  .nav-icon {
    font-size: 16px;
  }

  .discount-text,
  .contact-info {
    font-size: 12px;
  }
}

/* Sticky Navbar */
.stickk {
  position: sticky;
  top: 0;
  z-index: 1000;
}